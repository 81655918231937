import {createContext, useContext, useState} from 'react'
import Cart from '../components/Cart';
import useLocalStorage from '../hooks/useLocalStorage';

const CartContext = createContext({
    items: [],
    cartQuantity: 0,
    isOpen: false,
    addItem: (item) => {},
    removeItem: (id) => {},
    updateQty: (id,qty) => {},
    clearCart: (updates) => {},
    updateCartAvailable: () => {},
});


export function useCart(){
    return useContext(CartContext)
}

export function CartProvider( { children } ){

    const [items, setItems] = useLocalStorage("cart", []);
    const cartQuantity = items.reduce((quantity, item) => item.quantity + quantity, 0);

    function addItem(add){  
        setItems(items => {         
            if(items.find(item => item.id === add.id) == null){
                
                return [...items, {...add, quantity: 1}]
            }else{
                return items.map(item => {
                    if(item.id === add.id){ 
                         return {...item, quantity: item.quantity + 1}               
                    }else{
                        return item;
                    }
                })
            }   
        })
    }

    function removeItem(id){
        setItems(items => {
            return items.filter(item => item.id !== id);
        })
    }

    function updateQty(id, qty){
        setItems(items => {
            return items.map(item => {
                if(item.id === id){ 
                     return {...item, quantity: qty}               
                }else{
                    return item;
                }
            })
        })
    }

    //Loop through current cart items
    //If new item array has matching id
    //Update the instock value
    //else return the existing instock value
    function updateCartAvailable(updates){
        setItems(items => {
            return items.map(item => {
                var update = updates.find(update => update.id === item.id);        
                if(update == null){        
                    return item;
                }else{
                    //Update quantity if current cart qty > new instock else keep quantity the same
                    var quantity = item.quantity > update.instock && update.instock > 0 ? update.instock : item.quantity;
                    //Mark qtyUpdatedFlag if 
                    var qtyUpdatedFlag = item.quantity > update.instock && update.instock > 0 ? true : false;
                    return {...item, instock: update.instock, quantity: quantity, qtyUpdatedFlag: qtyUpdatedFlag}  
    
                }
            })
        })
    }

    function clearCart(){
        setItems([]);
    }


    function getItemQuantity(id){
        return items.find(x => x.id === id)?.quantity || 0;
    }
    /*
    function increaseCartQuantity(id){
        setCartItems(curItems => {
            if(curItems.find(item => item.id === id) == null){
                return [...curItems, { id, quantity: 1}]
            }else{
                return curItems.map(item => {
                    if(item.id === id){
                        return {...item, quantity: item.quantity + 1}
                    }else{
                        return item;
                    }
                })
            }
        })   
    }
    
    function decreaseCartQuantity(id){
        setCartItems(curItems => {
            if(curItems.find(item => item.id === id)?.quantity === 1){
                return curItems.filter(item => item.id !== id);
            }else{
                return curItems.map(item => {
                    if(item.id === id){
                        return {...item, quantity: item.quantity - 1}
                    }else{
                        return item;
                    }
                })
            }
        })   
    }*/
    
    return( 
        <CartContext.Provider value={{
            items,
            cartQuantity,
            addItem,
            removeItem,
            updateQty,
            clearCart,
            updateCartAvailable,
            getItemQuantity
        }}>
            {children}
        </CartContext.Provider>
    )
}