import * as React from 'react';
import { useState, useEffect } from 'react';
import { Paper, Button, Stack, Grid, Typography } from '@mui/material';
import { useCart } from '../contexts/CartContext';
import { Link } from 'react-router-dom';

function ProductCard({id, name, image1, price, sales_price, instock}) {
    console.log(name+": "+sales_price)
  return (
       

            <Grid container className="productCard" justifyContent="space-evenly" alignItems="stretch">
                <Grid item xs={12} ><img src={"../images/products/" + image1} title={image1}/></Grid>
                <Grid item xs={12} className="title">
                    <Typography className="title">{name}</Typography>
                
                </Grid>
                <Grid item xs={12}>
                    <Typography className='price'>
                        {sales_price ?
                            
                            <>
                                
                                <span style={{color:'#F51414'}}>${sales_price}</span>&nbsp;&nbsp;
                                <span style={{textDecoration:'line-through',  fontWeight:'normal'}}>${price}</span>
                            </>

                        : 
                            <>
                                ${price}  
                            </>
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12} className="price">
                    <Link to = {`/viewProduct/${id}`} style={{textDecoration:'none'}}>
                        <Button className='sage'>
                            {instock > 0
                                ? "View Product"
                                : "Out of Stock"
                            }
                        </Button>
                    </Link>
                </Grid>
            </Grid>

     
  );
}

export default ProductCard;