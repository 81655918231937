import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import api from '../util/Api';
import { Button, Grid, Box, Typography, Stack, Snackbar, CircularProgress } from '@mui/material';
import ImageGallery from 'react-image-gallery';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Footer from '../components/Footer';

export default function Product() {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState({});
  const { items, addItem, getItemQuantity, removeItem } = useCart();
  const [disabled, setDisabled] = useState(false);
  
  // Manage Snackbar state (open and message)
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  
  const snackBarTimeoutRef = useRef(null); // Ref to store timeout

  // Fetch product data
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    setItem({});
    let controller = new AbortController();
    const config = {
      accept: 'application/json',
      signal: controller.signal,
    };

    const fetchData = async () => {
      var response = await api.get('/products/' + id, {}, config);
      if (response.status === 200) {
        setItem(response.data);
        isAddDisabled(response.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => controller.abort();
  }, [id]);

  // Handle Add Item action
  function handleAddItem(item) {
    addItem(item);
    showSnackbar('Item added to cart');
  }

  // Handle Remove Item action
  function handleRemoveItem(id) {
    removeItem(id);
    showSnackbar('Item removed from cart');
  }

  // Function to display the Snackbar with a new message
  function showSnackbar(message) {
    // If the Snackbar is already open, reset the timeout before updating the message
    if (snackBarOpen) {
      clearTimeout(snackBarTimeoutRef.current);
    }

    // Update the message state to trigger a re-render for the Snackbar
    setSnackBarMessage(message);
    setSnackBarOpen(true); // Open the Snackbar

    // Set a new timeout to close the Snackbar after 3 seconds
    snackBarTimeoutRef.current = setTimeout(() => {
      setSnackBarOpen(false); // Close the Snackbar
    }, 3000);
  }

  // Check if the add button should be disabled based on stock quantity
  function isAddDisabled(item) {
    let instock = item.instock;
    // if (items.length > 0) {
    //   items.filter((x) => x.id === item.id).map((x) => {
    //     instock -= x.quantity;
    //   });
    // }
    setDisabled(instock <= 0);
  }

  return (
    <>
      <Snackbar
        open={snackBarOpen} // Now managed by state
        message={snackBarMessage} // Use state for message content
        autoHideDuration={null} // Disable auto hide, manage it manually with timeout
      />
      <ResponsiveAppBar />
      <div className="contentWrapper">
        {loading ? (
          <Grid container xs={12} justifyContent="center" alignItems="center" sx={{ height: '100vh', width: '100vw' }}>
            <Grid item>
              <CircularProgress sx={{ margin: '0 auto', color: '#e9e5d5', justifySelf: 'center' }} />
            </Grid>
          </Grid>
        ) : (
          <Grid container sx={{ marginTop: { xs: '0px', md: '25px' }, display: 'flex' }} columnSpacing={6} justifyContent="flex-start" alignItems="center">
            {item ? (
              <>
                <Grid item xs={12} md={6} lg={4}>
                  {item?.images.length > 0 && (
                    <ImageGallery items={item.images} showNav={false} showPlayButton={false} showFullscreenButton={false} showIndex={false} />
                  )}
                </Grid>

                <Grid item xs={12} md={6} lg={8} justifyItems="center">
                  <Stack sx={{ padding: '20px' }}>
                    <Stack>
                      <Typography sx={{ fontSize: '1.5em', fontWeight: 'bold', fontFamily: 'Gelasio !important' }}>{item.name}</Typography>
                    </Stack>
                    <Stack sx={{ marginTop: '10px', textAlign: 'left' }}>
                      <Typography sx={{ fontSize: '1.05em', fontWeight: 'bold' }}>
                        {item.sales_price ? (
                          <>
                            <div style={{ color: '#F51414', fontWeight: 'bold' }}>SALE</div>
                            <span style={{ color: '#F51414' }}>${item.sales_price}</span>&nbsp;&nbsp;
                            <span style={{ textDecoration: 'line-through', fontWeight: 'normal' }}>${item.price}</span>
                          </>
                        ) : (
                          <>
                            ${item.price}
                          </>
                        )}
                      </Typography>
                    </Stack>
                    <Stack sx={{ marginTop: '20px' }}>
                      <Typography sx={{ fontSize: '1em', fontWeight: 'normal' }}>{item.description}</Typography>
                    </Stack>

                    <Stack>
                      <Button
                        sx={{ alignSelf: 'flex-end', width: '100%' }}
                        className="sage"
                        disabled={disabled}
                        onClick={() => {
                          if (getItemQuantity(item.id) > 0) {
                            handleRemoveItem(item.id);
                          } else {
                            handleAddItem(item);
                          }
                        }}
                      >
                        {!disabled ? (getItemQuantity(item.id) > 0 ? 'REMOVE FROM CART' : 'ADD TO CART') : 'OUT OF STOCK'}
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} display="flex" style={{ textAlign: 'center', width: '100%', marginTop: '20px', justifyContent: 'center' }}>
                Product not found.
              </Grid>
            )}
          </Grid>
        )}
      </div>
      <Footer />
    </>
  );
}
